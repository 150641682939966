<template>
  <LayoutDashboardPage title="">
    <AppSpinnerBig :loading="loading" />

    <AppCard v-if="!loading && referAFriendPromoInformation" class="invite-app-card" sheet>
      <div class="card-header-box-wrapper">
        <div class="title-box">
          <h1 class="invite-friend-title">
            {{ $t('InviteFriend.Referal.Title').value }}
            <span class="gift-card">
              {{
                $t('InviteFriend.Referal.GiftCardAmount', {
                  value: referAFriendPromoInformation.data.giftCardValue,
                }).value
              }}
            </span>
          </h1>
          <p class="info-text">
            {{
              $t('InviteFriend.ReferAFriend.InfoText', {
                value: referAFriendPromoInformation.data.qualifyingValue,
              }).value
            }}
          </p>
        </div>
        <AppFigure class="gift-background md:min-w-32 md:ml-6" theme="blue">
          <AppIcon name=" " class="gift-icon">
            <IconGift />
          </AppIcon>
        </AppFigure>
      </div>

      <div class="share-code-div">
        <label class="promo-code-label" for="promo-code">
          {{ $t('InviteFriend.Referal.Label').value }}
        </label>
        <AppInputText
          :value="promoCodeValue"
          id="promo-code"
          placeholder=""
          class="input-text"
          disabled
        >
          <template #rightIcon>
            <AppButton theme="icon" @click="copyCode">
              <AppIcon name=" " size="18">
                <IconCopy />
              </AppIcon>
            </AppButton>
          </template>
        </AppInputText>

        <AppButton
          analytics-name="share-code"
          theme="primary"
          class="share-btn"
          @click="shareHandler"
        >
          {{ $t('InviteFriend.Referal.ShareCodeBtn').value }}
        </AppButton>
      </div>

      <div class="mt-8">
        <h2 class="how-to">
          {{ $t('InviteFriend.Referal.HowToClaim').value }}
        </h2>
        <AppList>
          <template>
            <XePromoListItem
              :text="$t('InviteFriend.Referal.ShareCodeTitle').value"
              :sub-title="$t('InviteFriend.Referal.ShareCodeDetails').value"
              icon="IconShare"
            />

            <XePromoListItem
              :text="$t('InviteFriend.Referal.CodeTitle').value"
              :sub-title="$t('InviteFriend.ReferAFriend.CodeDetails').value"
              icon="IconCode"
            />

            <XePromoListItem
              :text="
                $t('InviteFriend.Referal.SendMoneyTitle', {
                  value: referAFriendPromoInformation.data.qualifyingValue,
                }).value
              "
              :sub-title="
                $t('InviteFriend.ReferAFriend.SendMoneyDetails', {
                  value: referAFriendPromoInformation.data.qualifyingValue,
                }).value
              "
              icon="IconSend"
            >
              <template #figure-icon>
                <AppFigure theme="blue">
                  <AppIcon name=" ">
                    <IconSend />
                  </AppIcon>
                </AppFigure>
              </template>
            </XePromoListItem>

            <XePromoListItem
              :text="$t('InviteFriend.Referal.RewardTitle').value"
              :sub-title="
                $t('InviteFriend.Referal.RewardDetails', {
                  value: referAFriendPromoInformation.data.qualifyingValue,
                }).value
              "
              icon="IconReward"
            />
          </template>
        </AppList>
      </div>
      <div class="flex items-center justify-center mt-2">
        <a class="terms-conds text-center cursor-pointer" @click="termsAndConditionsUrl">
          {{ $t('InviteFriend.Referal.TermsAndConditions').value }}
        </a>
      </div>
    </AppCard>
    <!-- keep it so that the margin bottom works properly -->
    <div class="margin-spacer">-</div>

    <!-- Share modal -->
    <XeShareModal
      v-model="share"
      :title="$t('PageSendMoneyFinished.ShareHeader').value"
      @gmail="shareGmail"
      @email="shareEmail"
      @clipboard="copyClipboard"
      @hide="hideModal"
      :body="referAFriendMobileShareableContent"
    />
  </LayoutDashboardPage>
</template>

<script>
import { ref, onBeforeMount } from '@vue/composition-api'

import LayoutDashboardPage from '@galileo/components/LayoutDashboardPage/LayoutDashboardPage'
import HelpDesk, { HelpDeskProperties } from '@galileo/constants/helpdesk.const'

import {
  useI18nStore,
  useAuthStore,
  useAppStore,
  usePromotionStore,
  useAnalyticsStore,
} from '@galileo/stores'

import XeShareModal from '@galileo/components/XeShareModal/XeShareModal.vue'
import XePromoListItem from '@galileo/components/XePromoListItem/XePromoListItem'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

import {
  useAlert,
  AppFigure,
  AppList,
  AppListItem,
  AppIcon,
  AppButton,
  AppSpinnerBig,
  AppInputText,
  AppCard,
  AppListItemTitle,
  AppListItemCaption,
} from '@oen.web.vue2/ui'

import { IconGift, IconSend } from '@oen.web.vue2/icons'
import { IconCopy, IconCode, IconReward, IconShare } from '@galileo/assets/icons/vue'
import { PROMOTIONS_TYPE } from '@galileo/constants/promotions.const'

export default {
  name: 'InviteFriend',
  components: {
    AppList,
    AppFigure,
    AppIcon,
    AppSpinnerBig,
    AppButton,
    AppInputText,
    AppCard,
    LayoutDashboardPage,
    AppListItem,
    AppListItemTitle,
    AppListItemCaption,
    IconGift,
    IconSend,
    useAlert,
    IconShare,
    IconCopy,
    IconCode,
    IconReward,
    HelpDeskProperties,
    XePromoListItem,
    XeShareModal,
  },
  setup() {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()
    const appStore = useAppStore()
    const promotionStore = usePromotionStore()
    const analyticsStore = useAnalyticsStore()
    const { lastLogin } = authStore

    const { add: addAlert } = useAlert()
    const promoCodeValue = ref('')
    const loading = ref(false)
    const share = ref(false)

    const referAFriendPromoInformation = ref(null)
    const referAFriendMobileShareableContent = ref('')
    onBeforeMount(async () => {
      promoCodeValue.value = await promotionStore.getPromotionCode()
      referAFriendMobileShareableContent.value = promotionStore.getTransactionShareBody(
        promoCodeValue.value
      )
      referAFriendPromoInformation.value = promotionStore.getPromotionDataByPromoName(
        PROMOTIONS_TYPE.REFER_FRIEND
      )
    })

    const termsAndConditionsUrl = () => {
      appStore.openHelpDesk({
        loadingRef: ref(false),
        constName: HelpDeskProperties.TERMS_AND_CONDITIONS_REFER_A_FRIEND,
        article: HelpDesk[HelpDeskProperties.TERMS_AND_CONDITIONS_REFER_A_FRIEND],
      })
    }

    const copyCode = async () => {
      try {
        await navigator.clipboard.writeText(promoCodeValue.value)
        copied()
      } catch (error) {
        addAlert($t('PageActivity.ErrorText').value, error)
      }
    }

    const shareHandler = () => {
      share.value = true
    }

    const hideModal = () => {
      share.value = false
    }

    const shareGmail = () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.REFER_A_FRIEND_CODE_SHARED,
        traits: {
          email: lastLogin,
          sharePlatform: 'Email',
        },
      })

      promotionStore.shareGmail(promoCodeValue.value)
      share.value = false
    }

    const shareEmail = () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.REFER_A_FRIEND_CODE_SHARED,
        traits: {
          email: lastLogin,
          sharePlatform: 'Email',
        },
      })

      promotionStore.shareEmail(promoCodeValue.value)
      share.value = false
    }

    const copyClipboard = async () => {
      promotionStore.shareClipboard(promoCodeValue.value)
      copied()
      share.value = false
    }

    function copied() {
      addAlert($t('InviteFriend.ReferAFriend.CopiedToClipboard').value)
    }

    return {
      $t,
      copyCode,
      termsAndConditionsUrl,
      promoCodeValue,
      loading,
      shareGmail,
      share,
      shareHandler,
      hideModal,
      copyClipboard,
      shareEmail,
      copied,
      referAFriendPromoInformation,
      referAFriendMobileShareableContent,
    }
  },
}
</script>

<style scoped>
.invite-app-card {
  @apply mt-0 mb-0 mx-auto;
  max-width: 40rem;

  ::v-deep .card-header-box-wrapper {
    @apply flex flex-row;
    .invite-friend-title {
      font-size: 32px;
      font-weight: 600;
      line-height: 44.8px;
      text-align: left;
      .gift-card {
        color: var(--theme-color-primary, theme('colors.blue.default'));
      }
    }
    .info-text {
      @apply mt-4 mb-8 text-base font-normal text-left;
      line-height: 22px;
      color: #636e82;
    }
    .gift-background {
      @apply w-32 h-32 flex items-center justify-center;
      min-width: 128px;
      .gift-icon {
        width: 60px;
        height: 67.5px;
      }
    }
  }
  .list-item {
    border-bottom-width: 0px;
  }
  .share-code-div {
    @apply relative flex gap-2.5 justify-between items-start;

    .promo-code-label {
      @apply text-secondary-text text-xs absolute top-1 z-10;
      left: 13px;
    }

    ::v-deep .input-input {
      @apply pt-4 font-bold;
      color: black;
    }
    .input-text {
      flex: 3;
    }
    .share-btn {
      flex: 2;
    }
  }
  .how-to {
    @apply text-2xl mb-4 mt-4 font-semibold text-left leading-8;
  }
  .terms-conds {
    @apply underline font-semibold text-sm leading-5 text-center relative;
    color: var(--theme-color-primary, theme('colors.blue.default'));
  }
  .margin-spacer {
    height: 1px;
    font-size: 1px;
    color: transparent;
    display: none;
  }

  @media (max-width: 600px) {
    ::v-deep .card-header-box-wrapper {
      @apply flex flex-col;
      .title-box {
        order: 2;
        .invite-friend-title {
          @apply text-2xl font-semibold text-left leading-8;
        }
      }
      .gift-background {
        @apply p-0 w-16 h-16 mb-4;
        min-width: 4rem;
        .gift-icon {
          @apply w-8;
          height: 35px;
        }
      }
    }
    .share-code-div {
      @apply flex flex-col;
      .input-text {
        @apply mb-1 w-full;
      }
    }
  }
}
</style>
