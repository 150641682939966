<template>
  <AppListItem>
    <template #figure>
      <AppFigure theme="blue">
        <slot name="figure-icon">
          <Component :is="iconImage" v-if="showIcon"></Component>
          <div v-else>
            {{ icon }}
          </div>
        </slot>
      </AppFigure>
    </template>

    <template #left>
      <AppListItemTitle>
          {{ text }}
      </AppListItemTitle>
      <AppListItemCaption v-if="subTitle"> {{ subTitle }} </AppListItemCaption>
      <slot name="error"></slot>
    </template>
  </AppListItem>
</template>

<script>
import { useI18nStore } from '@galileo/stores'
import { IconChevronRight } from '@oen.web.vue2/icons'

import { ref } from '@vue/composition-api'

import {
  AppIcon,
  AppFigure,
  AppListItem,
  AppListItemTitle,
  AppListItemCaption,
  AppList,
  AppSpinner,
} from '@oen.web.vue2/ui'

export default {
  name: 'XePromoListItem',
  components: {
    AppList,
    AppListItemCaption,
    AppListItemTitle,
    AppListItem,
    AppIcon,
    AppFigure,
    IconChevronRight,
    AppSpinner,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit, listeners }) {
    const { $t } = useI18nStore()
    let iconImage = props.icon
    let showIcon = true
    if (props.icon) {
      let icons = require('@galileo/assets/icons/vue')
      
      iconImage = icons[iconImage]
      if (!iconImage) {
        iconImage = props.icon
        showIcon = false
      }
    }
    const isLoading = ref(false)
    const callback = () => {
      isLoading.value = true
      if (listeners.select) {
        emit('select')
      } else {
        props.select()
      }
      setTimeout(() => {
        isLoading.value = false
      }, 1500)
    }
    return { $t, iconImage, showIcon, isLoading, callback }
  },
}
</script>
